import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container as ContainerBase } from "components/misc/Layouts";
import { CallApiFetchAuthorized } from "helpers/CallApiFetch";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AdminHeader from 'pages/Admin/Header.js';

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-1 sm:my-8 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`p-6 sm:p-12`;
const MainContent = tw.div`mt-4 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold items-center flex`;
const CustomTableRow = tw.div`flex p-0 w-full`;
const CustomTableCell = tw.div`p-0 inline-block`;
const TableLink = tw(Link)`inline-block w-full h-full p-4`;

//const Error = tw.p`font-medium bg-red-100 text-center p-4 mt-8`;

export default ({
  a14n = null,
  headingText = "Услуги"
}) => {

  const [groups, setGroups] = useState([]);

  useEffect(() => {
    CallApiFetchAuthorized('/api/services_list', null, a14n.token).then((result) => {
      setGroups((prevState) => {
        return [...prevState, ...result.data];
      });
    })
  }, [a14n.token]);

  return (
  <>
    <AdminHeader a14n={a14n} />
    <Container>
      <Content>
        <MainContainer>
          <MainContent>
            <Heading>
              {headingText}
            </Heading>

              {groups.map((service) => (
                <CustomTableRow key={service.id}>
                  <CustomTableCell tw="flex-grow"><TableLink to={`/admin/services/${service.id}`}>{service.name}</TableLink></CustomTableCell>
                  <CustomTableCell><TableLink to={`/admin/services/${service.id}`}>{parseInt(service.cost)}</TableLink></CustomTableCell>
                  { a14n.is_admin && <CustomTableCell><TableLink to={`/admin/services/edit/${service.id}`}>(ред.)</TableLink></CustomTableCell> }
                </CustomTableRow>
              ))}

            { a14n.is_admin && <Link to="/admin/services/add">Создать услугу</Link> }
          </MainContent>
        </MainContainer>
      </Content>
    </Container>
  </>
  );
}

