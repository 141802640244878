import React from "react";
import tw from "twin.macro";
import logoImage from "images/rocks/rocky_green.svg";
import styled from "styled-components";

const TicketContainer = tw.div`flex flex-col`;
const Ticket = styled.div`
  ${tw`shadow-2xl bg-purple-400 py-6 px-6 flex items-center rounded-2xl`}
  height: 175px;
  width: 295px;
`;
const TicketBack = styled.div`
  ${tw`mt-10 shadow-2xl bg-purple-400 py-6 px-6 flex rounded-2xl text-center items-center justify-center`}
  height: 175px;
  width: 295px;
  position: relative;
`

const TicketLeft = tw.div`pr-5`
const TicketSite = styled.span`
  ${tw`text-gray-900`}
  font-family: Indie Flower;
  font-size: 2rem;
`
const TicketQR = tw.img``

const TicketId = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.8rem;
  font-size: 0.8rem;
`


export default ({
  ticket = null,
  onlyFront = false
}) => {
  return (
    <TicketContainer>
      <Ticket>
        <TicketLeft>
          <img alt="Logo" width="110" src={logoImage} />
        </TicketLeft>
        <TicketQR download={`${ticket.id}.png`} src={ticket.qr.replace(/;base64/, `;name=${ticket.id}.png;base64`)}></TicketQR>
      </Ticket>

      {
        onlyFront? '': 
        <TicketBack>
          <TicketId>#{ticket.id}</TicketId>
          <TicketSite>cozyrock.kz</TicketSite>
        </TicketBack>
      }
    </TicketContainer>
  )
}