import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
//import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
//import { Map } from "components/features/map.js";

//const Address = tw.span`leading-relaxed`;
//const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

const map_pic_static_url = 'https://static.maps.2gis.com/1.0?' + 
   's=880x400&' + 
   'c=43.24098,76.8765' + 
   '&z=18' + 
   '&pt=43.24108,76.87632&' + 
   'pn=43.24072,76.87639,' + 
   '43.24067,76.87648,' + 
   '43.24067,76.87642,' + 
   '43.24059,76.87644,' +
   '43.240583,76.87639,' + 
   '43.24066,76.876385,' + 
   '43.24065,76.87631' +
   '~c:03ee10~f:03ee10a0';
//console.log(map_pic_static_url);

export default ({a14n = null}) => {
  return (
    <AnimationRevealPage>
      <Header a14n={a14n} />

      <div tw="pt-20 flex justify-center">
        <div tw="text-center">
          <Email><a href="mailto:info@cozyrock.kz">info@cozyrock.kz</a></Email>
          <Phone><a href="tel:+77077479291">+7-707-747-92-91</a></Phone>
        </div>
      </div>

      <div tw="pt-20 flex justify-center">
        {/*<Map />*/}
        <img alt="map" src={`${map_pic_static_url}`} />
      </div>
      <ContactDetails
        heading=""
        subheading="Наш адрес"
        description={<><p>Алматы, ул. Брусиловского 167, ЖК Алтын Булак 2, 6 блок.</p><p>По зеленой стрелке спуск по лестнице вниз, прямо по коридору 50 метров, офис справа возле входа во второй подъезд</p></>}
        cards={[]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
