import React, { useState, useEffect } from "react";

import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableRow,
} from "components/ui/table.js"
import { Link, useParams } from "react-router-dom";

import { CallApiFetch, CallApiFetchAuthorized } from "helpers/CallApiFetch.js";
import tw from "twin.macro";
import styled from "styled-components";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";

const Content = tw.div`mt-4 flex flex-col bg-white`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold text-center my-10`;
const AdminButton = styled.button`
  ${tw`mx-3 tracking-wide font-semibold cursor-pointer bg-primary-500 text-gray-100 py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out  justify-center focus:shadow-outline focus:outline-none`}
  min-width: 150px;
  
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`mx-3`}
  }
  &:disabled {
    ${tw`bg-gray-400 cursor-default`}
  }

  &.success {
    ${tw`bg-green-500`}
  }
`;

const SmallAdminButton = styled.button`
  ${tw`mx-3 tracking-wide font-semibold cursor-pointer bg-primary-500 text-gray-100 px-2 py-1 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out  justify-center focus:shadow-outline focus:outline-none`}
  
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`mx-3`}
  }
  &:disabled {
    ${tw`bg-gray-400 cursor-default`}
  }

  &.success {
    ${tw`bg-green-500`}
  }
`;

const BackLink = styled.button`
  ${tw`mt-10 tracking-wide font-semibold cursor-pointer bg-primary-500 text-gray-100 py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out  justify-center focus:shadow-outline focus:outline-none`}
  min-width: 200px;
`

export default ({
  a14n = null
}) => {
  let { id } = useParams();
  const [logItems, setLogItems] = useState([]);

  const [page, setPage] = useState(0);
  const [allLoaded, setAllLoaded] = useState(false);
  const [undoError, setUndoError] = useState(false);

  function loadNextPage(event) {
    event.preventDefault();
    setPage((prevState) => {
      return prevState + 1
    });
  }

  useEffect(() => {
    CallApiFetch(`/api/tickets_public/log/${id}?page=${page}`, null).then((result) => {
      setLogItems((prevState) => {
        if (result.data.length < 20) {
          setAllLoaded(true);
        }
        return [...prevState, ...result.data];
      });
    })
  }, [page, a14n.token, id]);

  function undoLogItem(id) {
    if (window.confirm('Вы действительно хотите отменить посещение?')) {
      CallApiFetchAuthorized(`/api/tickets/undo/${id}`, null, a14n.token).then((result) => {
        if (result.success) {
          setLogItems((prevState) => {
            prevState.shift();
            return [...prevState];
          });
        }
        else {
          setUndoError(result.message);
        }
      });
    }
  }

  return (
    <AnimationRevealPage>
      <Header a14n={a14n} />
      <Content>
        <Heading>Журнал посещений и оплаты</Heading>

        {logItems.length === 0 && 'Загрузка...' }
        <Table>
          <TableCaption>{!allLoaded && <AdminButton onClick={loadNextPage}>Подгрузить ещё</AdminButton>}</TableCaption>
          <TableBody>
            {logItems.map((logItem, index) => (
              <TableRow key={logItem.id}>
                <TableCell>{logItem.dateTime}</TableCell>
                <TableCell>{logItem.text}
                {(a14n.is_admin || a14n.is_trainer) && index === 0 && logItem.cost > 0 &&
                  <SmallAdminButton onClick={() => undoLogItem(logItem.id)}>{undoError ? undoError : 'Отменить'}</SmallAdminButton>
                }</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Link to={`/ticket/${id}`}><BackLink>Вернуться назад</BackLink></Link>
      </Content>
    </AnimationRevealPage>
  )
}
