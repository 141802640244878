import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Header from "components/headers/light.js";
import TopBlock from "components/front/TopBlock.js";
import FeatureStats from "components/front/MainFeature.js";
import OurTeam from "components/front/OurTeam.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Schedule from "components/front/Schedule.js";
import InstagramBlock from "components/front/InstagramBlock.js";
import Testimonial from "components/front/Testimonials.js";
import FAQ from "components/faqs/SingleCol.js";
import BottomBlock from "components/front/BottomBlock.js";
import Footer from "components/footers/MiniCenteredFooter.js";

import WonderingImage from "images/rocks/wondering.svg";
import InLoveImage from "images/rocks/in_love.svg";
import SleepyImage from "images/rocks/sleepy.svg";
import SatisfiedImage from "images/rocks/satisfied.svg";
import SaddyImage from "images/rocks/saddy.svg";
import AmazyImage from "images/rocks/amazy.svg";

const Image = tw.img`w-20 inline`

const HighlightedText = tw.span`text-primary-500`

export default ({a14n = null}) => {
  return (
    <AnimationRevealPage>
      <Header a14n={a14n} />
      <TopBlock />
      <div id="about">
        <FeatureStats />
      </div>
      <div id="schedule">
        <Schedule 
          heading={<>Расписание <HighlightedText>тренировок</HighlightedText> <Image src={WonderingImage} /></>}
          description=""
                />
      </div>
      <OurTeam
        heading={<>Создано скалолазами <span tw="text-primary-500">для скалолазов</span> <Image src={AmazyImage} />
    </>}
       />
      <Testimonial 
        heading={<>Отзывы наших <HighlightedText>посетителей</HighlightedText> <Image src={InLoveImage} /></>}
      />
      <div id="pricing">
        <Pricing 
          heading={<>Наши <HighlightedText>цены</HighlightedText> <Image src={SleepyImage} /></>}
        />
      </div>
      <FAQ
        heading={<>Ответы на часто задаваемые <HighlightedText>вопросы</HighlightedText> <Image src={SatisfiedImage} /></>}
      />
      <InstagramBlock
        subheading=""
        heading={<>Наш <HighlightedText>Instagram</HighlightedText> <Image src={WonderingImage} /></>}
      />
      <BottomBlock
        subheading={<>Не нашли что искали? <Image src={SaddyImage} /> </>}
        heading="Напишите или позвоните"
        primaryLinkText="Написать на WhatsApp"
        primaryLinkUrl="https://wa.me/77079643390"
        secondaryLinkText="Позвонить"
        secondaryLinkUrl="tel:+77077479291"
      />
      <Footer />
    </AnimationRevealPage>
  );
}
