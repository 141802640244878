import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "components/ui/table.js"
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Container as ContainerBase } from "components/misc/Layouts";
import { CallApiFetchAuthorized } from "helpers/CallApiFetch";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AdminHeader from 'pages/Admin/Header.js';

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-1 sm:my-8 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`p-6 sm:p-12`;
const MainContent = tw.div`mt-4 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold items-center flex`;

//const Error = tw.p`font-medium bg-red-100 text-center p-4 mt-8`;

export default ({
  a14n = null,
  headingText = "Группы"
}) => {
  const params = useParams();

  const [group, setGroup] = useState({group: {}, members: []});

  useEffect(() => {
    CallApiFetchAuthorized('/api/groups/show/' + params.id, null, a14n.token).then((result) => {
      setGroup((prevState) => {
        console.log('prevState=', prevState);
        return {...prevState, ...result.data};
      });
    })
  }, [a14n.token, params.id]);

  return (
  <>
    <AdminHeader a14n={a14n} />
    <Container>
      <Content>
        <MainContainer>
          <MainContent>
            <Heading>
              { group.group.name && `Группа ${group.group.name}`}
            </Heading>
 
             <Table>
              <TableBody>
                {group.members.map((member) => (
                  <TableRow key={member.id}>
                    <TableCell><Link to={`/ticket/${member.secret}`}>{member.name}</Link></TableCell>
                    <TableCell><Link to={`https://wa.me/${member.phone.replace(/[^0-9]/g, "")}`} target="_blank">{member.phone}</Link></TableCell>
                    <TableCell><Link to={`/ticket/${member.secret}`}>{member.count}/{member.last_count}</Link></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <br />
            <Link to={`/admin/groups/graph/${params.id}`}>График посещаемости >></Link>
          </MainContent>
        </MainContainer>
      </Content>
    </Container>
  </>
  );
}

