import React, { useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import { Container as ContainerBase } from "components/misc/Layouts";
import { CallApiPutAuthorized } from "helpers/CallApiFetch";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import AdminHeader from 'pages/Admin/Header.js';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ticketTypes from "helpers/TicketTypes";
import paymentTypes from "helpers/PaymentTypes";

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-1 sm:my-8 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`p-6 sm:p-12`;
const MainContent = tw.div`mt-4 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold items-center flex`;

const TicketCountSwitcher = tw.div`block w-full max-w-xs sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const TicketSwitchButton = styled.button`
  ${tw`w-1/2 sm:w-16 text-center py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;

const PaymentTypeSwitcher = tw.div`block w-full max-w-xs sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;

const Form = tw.form`mx-auto max-w-xs pt-8`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const InputCheckbox = tw.input`mr-1`
const Checkbox = tw.label`font-medium text-gray-500`
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const P = tw.p`py-3 pt-6`;

const onlyCountries = ['af','al','dz','ad','ao','ag','ar','am','aw','au','at','az','bs','bh','bd','bb','by','be','bz','bj','bt','bo','ba','bw','br','io','bn','bg','bf','bi','kh','cm','ca','cv','bq','cf','td','cl','cn','co','km','cd','cg','cr','ci','hr','cu','cw','cy','cz','dk','dj','dm','do','ec','eg','sv','gq','er','ee','et','fj','fi','fr','gf','pf','ga','gm','ge','de','gh','gr','gd','gp','gu','gt','gn','gw','gy','ht','hn','hk','hu','is','in','id','ir','iq','ie','il','it','jm','jp','jo','kz','ke','ki','xk','kw','kg','la','lv','lb','ls','lr','ly','li','lt','lu','mo','mk','mg','mw','my','mv','ml','mt','mh','mq','mr','mu','mx','fm','md','mc','mn','me','ma','mz','mm','na','nr','np','nl','nc','nz','ni','ne','ng','kp','no','om','pk','pw','ps','pa','pg','py','pe','ph','pl','pt','pr','qa','re','ro','rw','kn','lc','vc','ws','sm','st','sa','sn','rs','sc','sl','sg','sk','si','sb','so','za','kr','ss','es','lk','sd','sr','sz','se','ch','sy','tw','tj','tz','th','tl','tg','to','tt','tn','tr','tm','tv','ug','ua','ae','gb','us','uy','uz','vu','va','ve','vn','ye','zm','zw'];
const Error = tw.p`font-medium bg-red-300 text-center p-4 mt-8`;

export default ({
  a14n = null
}) => {
  let { secret } = useParams();
  const [error, setError] = useState(false);
  const [redirect, setRedirect] = useState(false);
  // Кол-во посещений
  const [ticketTypeIndex, setTicketType] = useState(1);
  const [currentPrice, setPrice] = useState(ticketTypes[ticketTypeIndex].price);
  const [selectedPaymentType, setPaymentTypeIndex] = useState(1);

  function SubmitHandler(event) {
    event.preventDefault();
    
    let data = {
      name: event.target.name.value,
      price: event.target.price.value,
      phone: event.target.phone.value,
      count: ticketTypes[ticketTypeIndex].count,
      payment_type: selectedPaymentType,
      phid: event.target.phid.value,
      activate_on_visit: event.target.activate_on_visit.value
    }

    CallApiPutAuthorized('/api/tickets/' + secret, data, a14n.token).then((result) => {
      if (result.success) {
        setRedirect('/ticket/' + secret);
        setError(false);
      }
      else {
        setError(result.message);
      }
    })
  }

  function TicketSwitchHandler(e, index) {
    e.preventDefault()
    setTicketType(index)
    setPrice(ticketTypes[index].price)
  }

  return (
    <>
      <AdminHeader a14n={a14n} />
      <Container>
        <Content>
          <MainContainer>
            <MainContent>
              { redirect && <Navigate to={redirect} />}
              <Heading>
                Активировать абонемент
              </Heading>

              <Form onSubmit={SubmitHandler}>
                <Input name="name" placeholder="Имя посетителя" required="1" />
                <TicketCountSwitcher>
                  {ticketTypes.map((ticketType, index) => (
                    <TicketSwitchButton active={ticketTypeIndex === index}
                                        key={index}
                                        onClick={(e) => TicketSwitchHandler(e, index)}>
                      {ticketType.switcherText}
                    </TicketSwitchButton>
                  ))}
                </TicketCountSwitcher>
                <Input name="price" type="number" onChange={(e) => setPrice(e.target.value)} placeholder="стоимость" required="1" value={currentPrice} />
                <Input name="phid" type="number" placeholder="физический номер карты" required="1" />
                <PhoneInput
                  containerStyle={tw`mt-5`} 
                  inputStyle={{...tw`w-full pr-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`, ...{height: 'auto', lineHeight: 'normal'}}}
                  buttonStyle={tw`bg-gray-100 border border-gray-200`}
                  country='kz'
                  onlyCountries={onlyCountries}
                  inputProps={{name: 'phone', required: true}}
                />

                <PaymentTypeSwitcher>
                  {paymentTypes.map((paymentType, index) => (
                    <SwitchButton active={selectedPaymentType === index}
                                  key={index}
                                  onClick={(e) => {e.preventDefault(); setPaymentTypeIndex(index)}}>
                      {paymentType.switcherText}
                    </SwitchButton>
                  ))}
                </PaymentTypeSwitcher>

                <P>
                  <Checkbox><InputCheckbox type="checkbox" name="activate_on_visit" value="1" /> Активировать при первом посещении</Checkbox>
                </P>
                <SubmitButton><span className="text">Активировать</span></SubmitButton>
                {error && <Error>{error}</Error>}
              </Form>    

            </MainContent>
          </MainContainer>
        </Content>
      </Container>
  </>
    )
}