import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableRow,
} from "components/ui/table.js"
import { Link } from "react-router-dom";
import { Container as ContainerBase } from "components/misc/Layouts";
import { CallApiFetchAuthorized } from "helpers/CallApiFetch";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import AdminHeader from 'pages/Admin/Header.js';

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-1 sm:my-8 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`p-6 sm:p-12`;
const MainContent = tw.div`mt-4 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold items-center flex`;

const AdminButton = styled.button`
  ${tw`mx-3 tracking-wide font-semibold bg-primary-500 text-gray-100 w-40 py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out  justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`mx-3`}
  }
`;

//const Error = tw.p`font-medium bg-red-100 text-center p-4 mt-8`;

export default ({
  a14n = null,
  headingText = "Абонементы"
}) => {


  const [tickets, setTickets] = useState([]);

  const [page, setPage] = useState(0);
  const [allLoaded, setAllLoaded] = useState(false);

  function loadNextPage(event) {
    event.preventDefault();
    setPage((prevState) => {
      return prevState + 1
    });
  }

  useEffect(() => {
    CallApiFetchAuthorized('/api/tickets?page=' + page, null, a14n.token).then((result) => {
      setTickets((prevState) => {
        if (result.data.length === 0) {
          setAllLoaded(true);
        }
        return [...prevState, ...result.data];
      });
    })
  }, [page, a14n.token]);

  return (
  <>
    <AdminHeader a14n={a14n} />
    <Container>
      <Content>
        <MainContainer>
          <MainContent>
            <Heading>
              {headingText}
            </Heading>
 
             <Table>
              <TableCaption>{!allLoaded && <AdminButton onClick={loadNextPage}>Подгрузить ещё</AdminButton>}</TableCaption>
              <TableBody>
                {tickets.map((ticket) => (
                  <TableRow key={ticket.id}>
                    <TableCell><Link to={`/ticket/${ticket.secret}`}>#{ticket.id}</Link></TableCell>
                    <TableCell><Link to={`/ticket/${ticket.secret}`}>{ticket.name}</Link></TableCell>
                    <TableCell><Link to={`https://wa.me/${ticket.phone.replace(/[^0-9]/g, "")}`} target="_blank">{ticket.phone}</Link></TableCell>
                    <TableCell><Link to={`/ticket/${ticket.secret}`}>{ticket.group}</Link></TableCell>
                    <TableCell><Link to={`/ticket/${ticket.secret}`}>{ticket.count}</Link></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

          </MainContent>
        </MainContainer>
      </Content>
    </Container>
  </>
  );
}

