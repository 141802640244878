import React, { forwardRef } from "react";

import tw from "twin.macro";

const Table = forwardRef(({ className, ...props }, ref) => {
  const Container = tw.div`w-full overflow-auto flex justify-center`;
  const Table = tw.table`w-full caption-bottom text-sm`;
  return <Container>
    <Table
      ref={ref}
      {...props}
    />
  </Container>
})
Table.displayName = "Table"

const TableHeader = forwardRef(({ className, ...props }, ref) => {
  const Thead = tw.thead`[&_tr]:border-b`

  return <Thead ref={ref} {...props} />

})
TableHeader.displayName = "TableHeader"

const TableBody = forwardRef(({ className, ...props }, ref) => {
  const Tbody = tw.tbody`[&_tr:last-child]:border-0`
  return <Tbody
    ref={ref}
    {...props}
  />
})
TableBody.displayName = "TableBody"

const TableFooter = forwardRef(({ className, ...props }, ref) => {
  const Tfoot = tw.tfoot`bg-primary-200 font-medium text-primary-500`
  return <Tfoot
    ref={ref}
    {...props}
  />
})
TableFooter.displayName = "TableFooter"

const TableRow = forwardRef(({ className, ...props }, ref) => {
  const Tr = tw.tr`border-b transition-colors hover:bg-gray-100` // data-[state=selected]:bg-muted`
  return <Tr
    ref={ref}
    {...props}
  />
})
TableRow.displayName = "TableRow"

const TableHead = forwardRef(({ className, ...props }, ref) => {
  const Th = tw.th`py-2 px-4 text-left align-middle font-medium text-primary-500 [&:has([role=checkbox])]:pr-0`
  return <Th
    ref={ref}
    {...props}
  />
})
TableHead.displayName = "TableHead"

const TableCell = forwardRef(({ className, ...props }, ref) => {
  const Td = tw.td`p-4 align-middle [&:has([role=checkbox])]:pr-0`
  return <Td
    ref={ref}
    {...props}
  />
})
TableCell.displayName = "TableCell"

const TableCaption = forwardRef(({ className, ...props }, ref) => {
  const Caption = tw.caption`mt-4 text-sm text-primary-500`
  return <Caption
    ref={ref}
    {...props}
  />
})
TableCaption.displayName = "TableCaption"

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption
}
