import React, { useState, useEffect } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "components/ui/table.js"

import { Link, useParams } from "react-router-dom";
import { CallApiFetchAuthorized } from "helpers/CallApiFetch.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import AdminHeader from 'pages/Admin/Header.js';

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-1 sm:my-8 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`p-6 sm:p-12`;
const MainContent = tw.div`mt-4 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold items-center flex`;
const SubHeading = tw.h2`items-center flex mt-4`;


const MonthButton = styled(Link)`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-32 py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex justify-center flex-nowrap focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const Buttons = tw.div`flex w-full justify-evenly`

/*const BackLink = styled.button`
  ${tw`mt-10 tracking-wide font-semibold cursor-pointer bg-primary-500 text-gray-100 py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out  justify-center focus:shadow-outline focus:outline-none`}
  min-width: 200px;
`*/

function IncomeReport({
  a14n = null,
  link = '/api/report_income',
  linkPrefix = '/admin/report_income'
}) {
  const [data, setData] = useState({log: null});

  useEffect(() => {
    CallApiFetchAuthorized(link, null, a14n.token).then((result) => {
      setData(result.data);
    })
  }, [link, a14n.token]);

  return (
  <>
    <AdminHeader a14n={a14n} />
    <Container>
      <Content>
        <MainContainer>
          <MainContent>
            <Heading>
              Отчёт {data.month && `за ${data.month.name}`}
            </Heading>


            {data.log === null ? 'Загрузка...': (data.log.length === 0 ? '-': '') }
            <Table>
              <TableBody>
                <TableRow key="title">
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>Наличные</TableCell>
                  <TableCell>Безнал</TableCell>
                  <TableCell>Кол-во</TableCell>
                </TableRow>
                {(Object.keys(data.log || {}) || []).map((logKey) => (
                  <TableRow key={logKey}>
                    <TableCell>{data.names[logKey]}</TableCell>
                    <TableCell>{data.log[logKey].cash}</TableCell>
                    <TableCell>{data.log[logKey].paybywire}</TableCell>
                    <TableCell>{data.log[logKey].count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <SubHeading>
              Итого
            </SubHeading>


            <Table>
              <TableBody>
                {(Object.keys(data.total || {}) || []).map((totalKey) => (
                  <TableRow key={totalKey}>
                    <TableCell>{data.names[totalKey]}</TableCell>
                    <TableCell>{data.total[totalKey]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Buttons>
              {data.prevMonth && <MonthButton to={`${linkPrefix}/${data.prevMonth.date}`}>❮ {data.prevMonth.name}</MonthButton>
              }
              {data.nextMonth && <MonthButton to={`${linkPrefix}/${data.nextMonth.date}`}>{data.nextMonth.name} ❯</MonthButton>
              }
            </Buttons>

          </MainContent>
        </MainContainer>
      </Content>
    </Container>
  </>
  )
}


export function CurrentReport(_params) {
  return IncomeReport({..._params, link: '/api/report_income'})
}

export function MonthReport(_params) {
  const params = useParams();
  return IncomeReport({..._params, date: params.date, link: `/api/report_income/${params.date}`})
}