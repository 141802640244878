import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container as ContainerBase } from "components/misc/Layouts";
import { CallApiFetchAuthorized } from "helpers/CallApiFetch";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import AdminHeader from 'pages/Admin/Header.js';
import Ticket from 'components/cards/Ticket.js';

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-1 sm:my-8 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`p-6 sm:p-12`;
const MainContent = tw.div`mt-4 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold items-center flex`;

const AdminButtons = tw.div`flex mt-5`
const AdminButton = styled.button`
  ${tw`mx-3 tracking-wide font-semibold bg-primary-500 text-gray-100 w-40 py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out  justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`mx-3`}
  }
`;

const Tickets = tw.div`flex flex-wrap justify-center`;
const TicketContainer = tw.div`text-center mt-6 p-6`
const TicketName = tw.div`text-gray-600 mb-3`

export default ({
  a14n = null,
  headingText = "Незанятые абонементы"
}) => {


  const [tickets, setTickets] = useState([]);

  function createTickets(count) {
    CallApiFetchAuthorized('/api/tickets', {
      count: count,
    }, a14n.token).then((result) => {
      if (result.success) {
        setTickets((prevState) => {
          return [...prevState, ...result.data];
        });
      }
    })
  }

  const [page, setPage] = useState(0);
  const [allLoaded, setAllLoaded] = useState(false);

  function loadNextPage(event) {
    event.preventDefault();
    setPage((prevState) => {
      return prevState + 1
    });
  }

  useEffect(() => {
    CallApiFetchAuthorized('/api/tickets_inactive?page=' + page, null, a14n.token).then((result) => {
      setTickets((prevState) => {
        if (result.data.length === 0) {
          setAllLoaded(true);
        }
        return [...prevState, ...result.data];
      });
    })
  }, [page, a14n.token]);

  return (
  <>
    <AdminHeader a14n={a14n} />
    <Container>
      <Content>
        <MainContainer>
          <MainContent>
            <Heading>
              {headingText}
            </Heading>

            <Tickets>
              {tickets.map(ticket => {
                return (
                  <TicketContainer key={ticket.id}>
                    <TicketName>
                      <Link to={`/ticket/${ticket.secret}`}>{ticket.secret_short}</Link>
                    </TicketName>
                    
                    <Ticket ticket={ticket} onlyFront={true} />
                  </TicketContainer>);
              })}
              {tickets.length === 0 && <p>Нет абонементов, используйте кнопки ниже чтобы создать</p>}
            </Tickets>

            {!allLoaded && <AdminButton onClick={loadNextPage}>Подгрузить ещё</AdminButton>}
            
            <AdminButtons>
              <AdminButton onClick={() => createTickets(1)}><span className="text">Создать 1шт</span></AdminButton>
              <AdminButton onClick={() => createTickets(5)}><span className="text">Создать 5шт</span></AdminButton>
              <AdminButton onClick={() => createTickets(10)}><span className="text">Создать 10шт</span></AdminButton>
            </AdminButtons>

          </MainContent>
        </MainContainer>
      </Content>
    </Container>
  </>
  );
}

