const paymentTypes = [
  {
    text: "cash",
    switcherText: "Наличные",
  },
  {
    text: "wire",
    switcherText: "Безнал",
  }
];

export default paymentTypes;