import React, { useState, useEffect } from "react";

import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableRow,
} from "components/ui/table.js"
import { Link } from "react-router-dom";

import { CallApiFetchAuthorized } from "helpers/CallApiFetch.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import AdminHeader from 'pages/Admin/Header.js';

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-1 sm:my-8 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`p-6 sm:p-12`;
const MainContent = tw.div`mt-4 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold items-center flex`;


const AdminButton = styled.button`
  ${tw`mx-3 tracking-wide font-semibold cursor-pointer bg-primary-500 text-gray-100 py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out  justify-center focus:shadow-outline focus:outline-none`}
  min-width: 150px;
  
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`mx-3`}
  }
  &:disabled {
    ${tw`bg-gray-400 cursor-default`}
  }

  &.success {
    ${tw`bg-green-500`}
  }
`;

const GreyText = tw.span`text-gray-500`

/*const BackLink = styled.button`
  ${tw`mt-10 tracking-wide font-semibold cursor-pointer bg-primary-500 text-gray-100 py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out  justify-center focus:shadow-outline focus:outline-none`}
  min-width: 200px;
`*/
const two_slashes = '//'

export default ({
  a14n = null
}) => {
  const [logItems, setLogItems] = useState([]);

  const [page, setPage] = useState(0);
  const [allLoaded, setAllLoaded] = useState(false);

  function loadNextPage(event) {
    event.preventDefault();
    setPage((prevState) => {
      return prevState + 1
    });
  }

  useEffect(() => {
    CallApiFetchAuthorized(`/api/log?page=${page}`, null, a14n.token).then((result) => {
      setLogItems((prevState) => {
        if (result.data.length < 20) {
          setAllLoaded(true);
        }
        return [...prevState, ...result.data];
      });
    })
  }, [page, a14n.token]);


  return (
  <>
    <AdminHeader a14n={a14n} />
    <Container>
      <Content>
        <MainContainer>
          <MainContent>
            <Heading>
              Журнал посещений и оплаты
            </Heading>


            {logItems.length === 0 && 'Загрузка...' }
            <Table>
              <TableCaption>{!allLoaded && <AdminButton onClick={loadNextPage}>Подгрузить ещё</AdminButton>}</TableCaption>
              <TableBody>
                {logItems.map((logItem) => (
                  <TableRow key={logItem.id}>
                    <TableCell>{logItem.dateTime}</TableCell>
                    <TableCell><Link to={`/ticket/${logItem.secret}`}>{logItem.name}</Link></TableCell>
                    <TableCell>{logItem.text}</TableCell>
                    <TableCell><GreyText>{logItem.cost} {two_slashes} {logItem.trainer_name}</GreyText></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

          </MainContent>
        </MainContainer>
      </Content>
    </Container>
  </>
  )
}
