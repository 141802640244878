import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "components/ui/table.js"
import { Link } from "react-router-dom";
import { Container as ContainerBase } from "components/misc/Layouts";
import { CallApiFetchAuthorized } from "helpers/CallApiFetch";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AdminHeader from 'pages/Admin/Header.js';

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-1 sm:my-8 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`p-6 sm:p-12`;
const MainContent = tw.div`mt-4 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold items-center flex`;

//const Error = tw.p`font-medium bg-red-100 text-center p-4 mt-8`;

export default ({
  a14n = null,
  headingText = "Группы"
}) => {


  const [groups, setGroups] = useState([]);

  useEffect(() => {
    CallApiFetchAuthorized('/api/groups_list', null, a14n.token).then((result) => {
      setGroups((prevState) => {
        return [...prevState, ...result.data];
      });
    })
  }, [a14n.token]);

  return (
  <>
    <AdminHeader a14n={a14n} />
    <Container>
      <Content>
        <MainContainer>
          <MainContent>
            <Heading>
              {headingText}
            </Heading>
 
             <Table>
              <TableBody>
                {groups.map((group) => (
                  <TableRow key={group.id}>
                    <TableCell><Link to={`/admin/groups/${group.id}`}>{group.name}</Link></TableCell>
                    <TableCell><Link to={`/admin/groups/${group.id}`}>{group.trainer_name}</Link></TableCell>
                    <TableCell><Link to={`/admin/groups/${group.id}`}>{group.count}</Link></TableCell>
                    <TableCell><Link to={`/admin/groups/edit/${group.id}`}>(ред.)</Link></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

          </MainContent>
        </MainContainer>
      </Content>
    </Container>
  </>
  );
}

