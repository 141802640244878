import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import CallApiFetch from "helpers/CallApiFetch";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/signup-illustration.svg";
import logo from "images/logo.svg";
import { ReactComponent as SignInIcon } from "feather-icons/dist/icons/log-in.svg";

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/4 xl:w-1/2 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const Error = tw.p`font-medium bg-red-300 text-center p-4 mt-8`;
let redirect = false;

export default ({
  a14n = null,
  setA14n = function() {},
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "Вход для тренеров",
  submitButtonText = "Войти",
  SubmitButtonIcon = SignInIcon,
  tosUrl = "#",
  privacyPolicyUrl = "#",
  signInUrl = "#"
}) => {


  function checkRedirect(auth) {
    if (auth && auth.token && auth.name) {
      if (auth.is_admin || auth.is_trainer) {
        redirect = '/admin/services/my';
      }
      else {
        redirect = '/';
      }
    }
  }

  checkRedirect(a14n);

  useEffect(function() {
    checkRedirect(a14n);
  }, [a14n]);

  const [error, setError] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    setError(false);

    CallApiFetch('/api/login', {
      email: data.get('email'),
      password: data.get('password'),
    }).then((result) => {
      if (result.success) {
        setError(false);
        setA14n(prevState => {
          return result.data
        });
      }
      else {
        setError('Не удалось войти, проверьте email и пароль');
      }
    })
  };

  return (
  <AnimationRevealPage>
    <Container>
      <Content>
        <MainContainer>
          { redirect && <Navigate to={redirect} />}
          <LogoLink href={logoLinkUrl}>
            <LogoImage src={logo} />
          </LogoLink>
          <MainContent>
            <Heading>{headingText}</Heading>
            <FormContainer>
              <Form onSubmit={handleSubmit}>
                <Input name="email" type="email" placeholder="Email" />
                <Input name="password" type="password" placeholder="Пароль" />
                <SubmitButton type="submit">
                  <SubmitButtonIcon className="icon" />
                  <span className="text">{submitButtonText}</span>
                </SubmitButton>
                {error && <Error>{error}</Error>}
              </Form>
            </FormContainer>
          </MainContent>
        </MainContainer>
      </Content>
    </Container>
  </AnimationRevealPage>
  );
}
