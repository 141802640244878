import React, { useState, useEffect } from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { css } from "styled-components/macro"; //eslint-disable-line


import ContactUsPage from "pages/ContactUs.js";
import PricingPage from "pages/Pricing.js";
import SchedulePage from "pages/Schedule.js";

import CozyRockMainPage from "CozyRockMainPage.js";
import WebcamPage from "pages/WebCam.js";
import SignInPage from "pages/SignIn.js";
import SignOutPage from "pages/SignOut.js";
import Page from "Page.js";

import TicketsIndexPage from "pages/Tickets/Index.js";
import TicketsLogPage from "pages/Tickets/Log.js";
import TicketsGiftPage from "pages/Tickets/Gift.js";

import AdminCombinedLog from "pages/Admin/CombinedLog.js";
import AdminExpiringPage from "pages/Admin/Expiring.js";
import { CurrentReport as AdminCurrentReport } from "pages/Admin/Report.js";
import { ByMonthReport as AdminByMonthReport } from "pages/Admin/Report.js";
import { MyReport as AdminMyReport } from "pages/Admin/Report.js";
import { MyReportMonth as AdminMyReportByMonth } from "pages/Admin/Report.js";
import { CurrentReport as AdminIncomeReport } from "pages/Admin/IncomeReport.js";
import { MonthReport as AdminIncomeReportByMonth  } from "pages/Admin/IncomeReport.js";

import AdminTicketsIndexPage from "pages/Admin/Tickets/Index.js";
import AdminTicketsInactivePage from "pages/Admin/Tickets/Inactive.js";
import AdminTicketsActivatePage from "pages/Admin/Tickets/Activate.js";
import AdminTicketsPaymentPage from "pages/Admin/Tickets/Payment.js";
import AdminGroupsIndexPage from "pages/Admin/Groups/Index.js";
import AdminGroupsShowPage from "pages/Admin/Groups/Show.js";
import AdminGroupsGraphPage from "pages/Admin/Groups/Graph.js";
import { CreateGroup as AdminGroupsCreatePage } from "pages/Admin/Groups/Edit.js";
import { EditGroup as AdminGroupsEditPage } from "pages/Admin/Groups/Edit.js";

import AdminServicesIndexPage from "pages/Admin/Services/Index.js";
import AdminServicesShowPage from "pages/Admin/Services/Show.js";
import { CreateService as AdminServicesCreatePage } from "pages/Admin/Services/Edit.js";
import { EditService as AdminServicesEditPage } from "pages/Admin/Services/Edit.js";
import { MyReport as AdminServicesMyReport} from "pages/Admin/Services/Report.js";
import { ByTrainerAndDate as AdminServicesReportByTrainerAndDate} from "pages/Admin/Services/Report.js";

//import ComponentRenderer from "ComponentRenderer.js";
//import MainLandingPage from "MainLandingPage.js";
//import ThankYouPage from "ThankYouPage.js";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const front_title = 'CozyRock – скалодром в Алматы, первый Kilter Board в Казахстане и Центральной Азии';
const title_suffix = 'CozyRock – скалодром в Алматы и Kilter Board';

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;

  // Authentication
  const [a14n, setA14n] = useState(function() {
    const state = {
      token: '',
      name:  '',
    }
    if (localStorage['token']) {
      state.token = localStorage['token'];
      state.name = localStorage['name'];
      state.is_admin = localStorage['is_admin'] === '1'? true: false;
      state.is_trainer = localStorage['is_trainer'] === '1'? true: false;
    }
    return state;
  });

  useEffect(() => {
    if (process.env?.NODE_ENV !== 'production') console.log('a14n', a14n);
    if (localStorage['token'] !== a14n.token) {
      localStorage['token'] = a14n.token;
      localStorage['name'] = a14n.name;
      localStorage['is_admin'] = a14n.is_admin? 1: 0;
      localStorage['is_trainer'] = a14n.is_trainer? 1: 0;
    }
  }, [a14n]);


  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/schedule" element={<Page title={`Расписание :: ${title_suffix}`} a14n={a14n}><SchedulePage /></Page>} />
          <Route path="/pricing" element={<Page title={`Цены :: ${title_suffix}`} a14n={a14n}><PricingPage /></Page>} />
          <Route path="/contact" element={<Page title={`Контакты :: ${title_suffix}`} a14n={a14n}><ContactUsPage /></Page>} />
          <Route path="/webcam" element={<Page title={`Вебкамеры :: ${title_suffix}`} a14n={a14n}><WebcamPage /></Page>} />
          <Route path="/signin" element={<Page title={`Вход для тренеров :: ${title_suffix}`}><SignInPage setA14n={setA14n} a14n={a14n} /></Page>} />
          <Route path="/ticket/:id" element={<Page title={`Ваш абонемент :: ${title_suffix}`}><TicketsIndexPage a14n={a14n} /></Page>} />
          <Route path="/ticket/log/:id" element={<Page title={`Журнал посещений :: ${title_suffix}`}><TicketsLogPage a14n={a14n} /></Page>} />
          <Route path="/gift/:id" element={<Page title={`Ваш подарок :: ${title_suffix}`}><TicketsGiftPage a14n={a14n} /></Page>} />

          <Route path="/" element={<Page title={front_title}><CozyRockMainPage a14n={a14n}/></Page>} />

          {
            a14n.token && a14n.name && 
              <Route path="/signout" element={<SignOutPage setA14n={setA14n} a14n={a14n} />} />
          }
          {
            (a14n.is_admin || a14n.is_trainer) &&
            <> 
              <Route path="/admin/report" element={<Page title="Отчёт за месяц"><AdminCurrentReport a14n={a14n} /></Page>} />
              <Route path="/admin/report/:date" element={<Page title="Отчёт за месяц"><AdminByMonthReport a14n={a14n} /></Page>} />
              <Route path="/admin/reportmy" element={<Page title="Отчёт за месяц"><AdminMyReport a14n={a14n} /></Page>} />
              <Route path="/admin/reportmy/:date" element={<Page title="Отчёт за месяц"><AdminMyReportByMonth a14n={a14n} /></Page>} />
              <Route path="/admin/report_income" element={<Page title="Отчёт за месяц"><AdminIncomeReport a14n={a14n} /></Page>} />
              <Route path="/admin/report_income/:date" element={<Page title="Отчёт за месяц"><AdminIncomeReportByMonth a14n={a14n} /></Page>} />
              <Route path="/admin/log" element={<Page title="Скалодром"><AdminCombinedLog a14n={a14n} /></Page>} />
              <Route path="/admin/expiring" element={<Page title="Скалодром"><AdminExpiringPage a14n={a14n} /></Page>} />
              <Route path="/admin/tickets" element={<Page title="Абонементы"><AdminTicketsIndexPage a14n={a14n} /></Page>} />
              <Route path="/admin/tickets/activate/:secret" element={<Page title="Активировать абонемент"><AdminTicketsActivatePage a14n={a14n} /></Page>} />
              <Route path="/admin/tickets/payment/:secret" element={<Page title="Оплата абонемента"><AdminTicketsPaymentPage a14n={a14n} /></Page>} />
              <Route path="/admin/groups/index" element={<Page title="Группы"><AdminGroupsIndexPage a14n={a14n} /></Page>} />
              <Route path="/admin/groups/add" element={<Page title="Создать группу"><AdminGroupsCreatePage a14n={a14n} /></Page>} />
              <Route path="/admin/groups/edit/:id" element={<Page title="Изменить группу"><AdminGroupsEditPage a14n={a14n} /></Page>} />
              <Route path="/admin/groups/:id" element={<Page title="Просмотр группы"><AdminGroupsShowPage a14n={a14n} /></Page>} />
              <Route path="/admin/groups/graph/:id" element={<Page title="График посещаемости"><AdminGroupsGraphPage a14n={a14n} /></Page>} />
              <Route path="/admin/services/index" element={<Page title="Услуги"><AdminServicesIndexPage a14n={a14n} /></Page>} />
              <Route path="/admin/services/add" element={<Page title="Создать услугу"><AdminServicesCreatePage a14n={a14n} /></Page>} />
              <Route path="/admin/services/edit/:id" element={<Page title="Изменить услугу"><AdminServicesEditPage a14n={a14n} /></Page>} />
              <Route path="/admin/services/:id" element={<Page title="Просмотр услуги"><AdminServicesShowPage a14n={a14n} /></Page>} />
              <Route path="/admin/services/my" element={<Page title="Мой отчёт за сегодня"><AdminServicesMyReport a14n={a14n} /></Page>} />
              <Route path="/admin/services/trainer/:id/:date" element={<Page title="Отчёт по тренеру и дате"><AdminServicesReportByTrainerAndDate a14n={a14n} /></Page>} />
            </>
          }
          {
            a14n.is_admin &&
            <> 
              <Route path="/admin/tickets_inactive" element={<Page title="Непроданные абонементы"><AdminTicketsInactivePage a14n={a14n} /></Page>} />
            </>
          }
        </Routes>
      </Router>
    </>
  );
}

// export default EventLandingPage;
// export default HotelTravelLandingPage;
// export default AgencyLandingPage;
// export default SaaSProductLandingPage;
// export default RestaurantLandingPage;
// export default ServiceLandingPage;
// export default HostingCloudLandingPage;

// export default LoginPage;
// export default SignupPage;
// export default PricingPage;
// export default AboutUsPage;
// export default ContactUsPage;
// export default BlogIndexPage;
// export default TermsOfServicePage;
// export default PrivacyPolicyPage;

// export default MainLandingPage;
