import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

const Container = tw.div`flex fixed left-0 right-0 top-0 bottom-0 bg-black bg-black bg-opacity-50 z-10 items-center justify-center`;
const PrimaryBackgroundContainer = tw.div`p-4 bg-gray-200 rounded-lg relative text-center`
const ConfirmButton = styled.button`
  ${tw`mx-3 tracking-wide font-semibold cursor-pointer bg-primary-500 text-gray-100 py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out  justify-center focus:shadow-outline focus:outline-none`}
  min-width: 150px;
`;
const Count = tw.h1`text-3xl`
const InputContainer = tw.div`pt-6 pb-10`

export function NumberSelectConfirm(params) {
  return (
    <Container onClick={params.closeHandler}>
      <PrimaryBackgroundContainer onClick={(e) => e.stopPropagation()}>
        <h2>{params.title}</h2>
        <Count>{params.count}</Count>
        <InputContainer>
          <input
            type="range"
            onChange={params.changeHandler}
            min={params.min}
            max={params.max}
            defaultValue={params.count}
            step={1} /><br />
        </InputContainer>
        <ConfirmButton onClick={() => {params.closeHandler(); params.resolve(params.count)}}>{params.button}</ConfirmButton>
      </PrimaryBackgroundContainer>
    </Container>
  );
};

export function useNumberSelectConfirm({
  title = "Укажите количество",
  button = "Подтвердить",
  defaultCount = 1,
  max = 10,
  min = 1
  }) {
  const [data, setData] = useState({
    count: defaultCount,
    title: title,
    button: button,
    max: max,
    min: min
  });

  function Handler() {
    return new Promise((resolve, reject) => {
      setData((prevData) => {
        return {...prevData, ...{
          visible: prevData.visible? false: true,
          resolve: resolve,
          reject: reject
        }};
      });
    });
  }

  function closeHandler() {
    setData((prevData) => {
      return {...prevData, ...{visible: prevData.visible? false: true} };
    });
  }

  function changeHandler(e) {
    setData((prevData) => {
      return {...prevData, count: e.target.value}
    })
  }
  /*useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((data) => setData(data));
  }, [url]);*/
  const ui = <>
    {data.visible && 
      <NumberSelectConfirm {...data}
        closeHandler={closeHandler}
        changeHandler={changeHandler} />}
  </>

  return [Handler, ui, setData];
};

export default NumberSelectConfirm;