import "slick-carousel/slick/slick.css";
import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
//import { css } from "styled-components/macro"; //eslint-disable-line
//import Slider from "react-slick";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { Container } from "components/misc/Layouts.js";
/*import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg";

const WebCamSlider = styled(Slider)`
  ${tw`w-full mt-10 text-center md:text-left`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;

const WebcamItem = tw.div`outline-none h-full flex! flex-col`;

const Controls = styled.div`
  ${tw`flex mt-8 sm:mt-0 justify-center`}
  .divider {
    ${tw`my-3 border-r`}
  }
`;
const ControlButton = styled.button`
  ${tw`mx-3 p-4 rounded-full transition duration-300 bg-gray-200 hover:bg-gray-300 text-primary-500 hover:text-primary-700 focus:outline-none focus:shadow-outline`}
  svg {
    ${tw`w-4 h-4 stroke-3`}
  }
`;
*/
const WebCamContainer = styled.div`
  ${tw`py-10 flex justify-center`}
`;
export default ({a14n = null}) => {
  //const [sliderRef, setSliderRef] = useState(null);
  //let isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  let rand = parseInt(Math.random()*3);
  return (
    <AnimationRevealPage>
      <Header a14n={a14n} />
      <Container>
        <WebCamContainer>Если нас нет на месте, значит мы где-то рядом, звоните <a href="tel:+77077479291">+7-707-747-92-91</a></WebCamContainer>
        { true ? 
          <>
            <WebCamContainer>
              <iframe title="kilter_webcam" width="640" height="480" src="https://rtsp.me/embed/974a9Hdb/" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen={true}></iframe>
            </WebCamContainer>
          </>

          :

          <>
            {
              rand === 0?
              <WebCamContainer>
                <iframe id="kilter_webcam" title="kilter_webcam" width="640" height="480" src="https://202708.gvideo.io/streams/202708_775475?autoplay=1&unmute=1" frameBorder="0" autoPlay={true} allow="autoplay; encrypted-media" allowFullScreen={true}></iframe>
              </WebCamContainer>
              : ''
            }
            {
              rand === 1?
              <WebCamContainer>
                <iframe id="slab_webcam" title="slab_webcam" width="640" height="480" src="https://202708.gvideo.io/streams/202708_775601?autoplay=1&unmute=1" frameBorder="0" autoPlay={true} allow="autoplay; encrypted-media" allowFullScreen={true}></iframe>
              </WebCamContainer>
              : ''
            }
            {
              rand === 2?
              <WebCamContainer>
                <iframe id="entry_webcam" title="entry_webcam" width="640" height="480" src="https://202708.gvideo.io/streams/202708_775604?autoplay=1&unmute=1" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen={true}></iframe>
              </WebCamContainer>
              : ''
            }
          </>
        }
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
