import React, {useState} from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

//import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import VasyaImg from "images/people/vasya.webp";
import KolyaImg from "images/people/kolya.webp";

//import SupportIconImage from "images/support-icon.svg";
//import ShieldIconImage from "images/shield-icon.svg";
//import CustomizeIconImage from "images/customize-icon.svg";
//import FastIconImage from "images/fast-icon.svg";
//import ReliableIconImage from "images/reliable-icon.svg";
//import SimpleIconImage from "images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
//const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const Image = styled.img(props => [
  props.imageRounded && tw`rounded-full`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
  tw`inline float-left`,
  'margin-top: -1rem; margin-right: 1rem'
]);

const SeasonContainer = tw.div`flex justify-center`
const SeasonSwitcher = tw.div`block border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 whitespace-nowrap transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;


export default ({ cards = null, heading = "Расписание", description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." }) => {
  const scheduleSeasons = [
    {
      text: "расписание на период каникул",
      switcherText: "Летний период",
    },
    {
      text: "расписание на учебный год",
      switcherText: "Осень, зима, весна",
    }
  ]

  const summerCards = [
    {
      title: "ПН",
      trainer: <span>   тренер Василий <Image imageRounded={true} width="50" height="50" src={VasyaImg} /></span>,
      description: <ul>
        <li tw='text-green-500'>09:00 &ndash; 10:30 группа 12-15 лет</li>
        <li tw='text-green-500'>10:30 &ndash; 12:00 группа 7-12 лет</li>
        <li tw='text-green-500'>12:00 &ndash; 13:30 группа 5-8 лет</li>
        <li tw='text-primary-500'>17:30 &ndash; 23:00 самостоятельные тренировки</li>
      </ul>
    },
    {
      title: "ВТ",
      trainer: <span>   тренер Николай <Image imageRounded={true} width="50" height="50" src={KolyaImg} /></span>,
      description: <ul>
        <li tw='text-green-500'>10:00 &ndash; 11:30 группа 7-13 лет</li>
        <li tw='text-green-500'>11:30 &ndash; 13:00 группа 7-13 лет</li>
        <li tw='text-primary-500'>13:00 &ndash; 23:00 самостоятельные тренировки и с тренером для взрослых</li>
      </ul> },
    {
      title: "СР",
      trainer: <span>   тренер Василий <Image imageRounded={true} width="50" height="50" src={VasyaImg} /></span>,
      description: <ul>
        <li tw='text-green-500'>09:00 &ndash; 10:30 группа 12-15 лет</li>
        <li tw='text-green-500'>10:30 &ndash; 12:00 группа 7-12 лет</li>
        <li tw='text-green-500'>12:00 &ndash; 13:30 группа 5-8 лет</li>
        <li tw='text-primary-500'>17:30 &ndash; 23:00 самостоятельные тренировки</li>
      </ul> },
    {
      title: "ЧТ",
      trainer: <span>   тренер Николай <Image imageRounded={true} width="50" height="50" src={KolyaImg} /></span>,
      description: <ul>
        <li tw='text-green-500'>10:00 &ndash; 11:30 группа 7-13 лет</li>
        <li tw='text-green-500'>11:30 &ndash; 13:00 группа 7-13 лет</li>
        <li tw='text-primary-500'>13:00 &ndash; 23:00 самостоятельные тренировки и с тренером для взрослых</li>
      </ul> },
    {
      title: "ПТ",
      trainer: <span>   тренер Василий <Image imageRounded={true} width="50" height="50" src={VasyaImg} /></span>,
      description: <ul>
        <li tw='text-green-500'>09:00 &ndash; 10:30 группа 12-15 лет</li>
        <li tw='text-green-500'>10:30 &ndash; 12:00 группа 7-12 лет</li>
        <li tw='text-green-500'>12:00 &ndash; 13:30 группа 5-8 лет</li>
        <li tw='text-primary-500'>17:30 &ndash; 23:00 самостоятельные тренировки</li>
      </ul> },
    {
      title: "СБ",
      trainer: <span>   тренер Николай <Image imageRounded={true} width="50" height="50" src={KolyaImg} /></span>,
      description: <ul>
        <li tw='text-green-500'>11:00 &ndash; 12:30 группа 5-13 лет</li>
        <li tw='text-primary-500'>12:30 &ndash; 23:00 самостоятельные тренировки и с тренером для взрослых</li>
      </ul> },
    { title: "ВС",
      trainer: <span>   тренер Николай <Image imageRounded={true} width="50" height="50" src={KolyaImg} /></span>,
      description: <ul>
        <li>09:00 &ndash; 14:00 тренировки для групп со своим тренером (по записи)</li>
        <li tw='text-primary-500'>14:00 &ndash; 23:00 самостоятельные тренировки и с тренером</li>
      </ul> }
  ];

  const autumnWinterSpringCards = [
    {
      title: "ПН",
      trainer: <span>   тренер Василий <Image imageRounded={true} width="50" height="50" src={VasyaImg} /></span>,
      description: <ul>
        <li>10:00 &ndash; 17:00 самостоятельные и индивидуальные тренировки (по записи)</li>
        <li tw='text-green-500'>18:00 &ndash; 19:30 группа 5-8 лет</li>
        <li tw='text-green-500'>19:30 &ndash; 21:00 группа 7-12 лет</li>
        <li tw='text-green-500'>21:00 &ndash; 23:00 группа 12-15 лет</li>
      </ul>
    },
    {
      title: "ВТ",
      trainer: <span>   тренер Николай <Image imageRounded={true} width="50" height="50" src={KolyaImg} /></span>,
      description: <ul>
        <li tw='text-green-500'>10:00 &ndash; 11:30 группа 7-13 лет</li>
        <li tw='text-green-500'>11:30 &ndash; 13:00 группа 7-13 лет</li>
        <li tw='text-primary-500'>13:00 &ndash; 23:00 самостоятельные тренировки и с тренером для взрослых</li>
      </ul> },
    {
      title: "СР",
      trainer: <span>   тренер Василий <Image imageRounded={true} width="50" height="50" src={VasyaImg} /></span>,
      description: <ul>
        <li>10:00 &ndash; 17:00 самостоятельные и индивидуальные тренировки (по записи)</li>
        <li tw='text-green-500'>18:00 &ndash; 19:30 группа 5-8 лет</li>
        <li tw='text-green-500'>19:30 &ndash; 21:00 группа 7-12 лет</li>
        <li tw='text-green-500'>21:00 &ndash; 22:30 группа 12-15 лет</li>
      </ul> },
    {
      title: "ЧТ",
      trainer: <span>   тренер Николай <Image imageRounded={true} width="50" height="50" src={KolyaImg} /></span>,
      description: <ul>
        <li tw='text-green-500'>10:00 &ndash; 11:30 группа 7-13 лет</li>
        <li tw='text-green-500'>11:30 &ndash; 13:00 группа 7-13 лет</li>
        <li tw='text-primary-500'>13:00 &ndash; 23:00 самостоятельные тренировки и с тренером для взрослых</li>
      </ul> },
    {
      title: "ПТ",
      trainer: <span>   тренер Василий <Image imageRounded={true} width="50" height="50" src={VasyaImg} /></span>,
      description: <ul>
        <li>10:00 &ndash; 17:00 самостоятельные и индивидуальные тренировки (по записи)</li>
        <li tw='text-green-500'>18:00 &ndash; 19:30 группа 5-8 лет</li>
        <li tw='text-green-500'>19:30 &ndash; 21:00 группа 7-12 лет</li>
        <li tw='text-green-500'>21:00 &ndash; 22:30 группа 12-15 лет</li>
      </ul> },
    {
      title: "СБ",
      trainer: <span>   тренер Николай <Image imageRounded={true} width="50" height="50" src={KolyaImg} /></span>,
      description: <ul>
        <li tw='text-green-500'>11:00 &ndash; 12:30 группа 5-13 лет</li>
        <li tw='text-primary-500'>12:30 &ndash; 23:00 самостоятельные тренировки и с тренером для взрослых</li>
      </ul> },
    { title: "ВС",
      trainer: <span>   тренер Николай <Image imageRounded={true} width="50" height="50" src={KolyaImg} /></span>,
      description: <ul>
        <li>09:00 &ndash; 14:00 тренировки для групп со своим тренером (по записи)</li>
        <li tw='text-primary-500'>14:00 &ndash; 23:00 самостоятельные тренировки и с тренером</li>
      </ul> }
  ];

  //if (!cards) cards = summerCards;
  // June, July, August are 5, 6, 7
  const monthIndex = (new Date()).getMonth();
  const [activeSeasonIndex, setActiveSeasonIndex] = useState(monthIndex > 4 && monthIndex < 8? 0: 1);

  return (
    <Container>
      <Heading>

      {heading}

      </Heading>
      {description && <Description>{description}</Description>}
      <VerticalSpacer />

      <SeasonContainer>
        <SeasonSwitcher>
          {scheduleSeasons.map((season, index) => (
            <SwitchButton active={activeSeasonIndex === index} key={index} onClick={() => setActiveSeasonIndex(index)}>{season.switcherText}</SwitchButton>
          ))}
        </SeasonSwitcher>
      </SeasonContainer>

      <ThreeColumnContainer>
        {activeSeasonIndex === 0 &&
          <>
          {summerCards.map((card, i) => (
            <Column key={i}>
              <Card>
                <span className="textContainer">
                  <span className="title">{card.title || "Fully Secure"}</span>{card.trainer}
                  <p className="description">
                    {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                  </p>
                </span>
              </Card>
            </Column>
          ))}
          </>
        }

        {activeSeasonIndex === 1 &&
          <>
          {autumnWinterSpringCards.map((card, i) => (
            <Column key={i}>
              <Card>
                <span className="textContainer">
                  <span className="title">{card.title || "Fully Secure"}</span>{card.trainer}
                  <p className="description">
                    {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                  </p>
                </span>
              </Card>
            </Column>
          ))}
          </>
        }
      </ThreeColumnContainer>

      <DecoratorBlob />
    </Container>
  );
};
