import React, { useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import { Container as ContainerBase } from "components/misc/Layouts";
import { CallApiPutAuthorized } from "helpers/CallApiFetch";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import AdminHeader from 'pages/Admin/Header.js';
import 'react-phone-input-2/lib/style.css'
import ticketTypes from "helpers/TicketTypes";
import paymentTypes from "helpers/PaymentTypes";

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-1 sm:my-8 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`p-6 sm:p-12`;
const MainContent = tw.div`mt-4 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold items-center flex`;

const TicketCountSwitcher = tw.div`block w-full max-w-xs sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const TicketSwitchButton = styled.button`
  ${tw`w-1/2 sm:w-16 text-center py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;
const PaymentTypeSwitcher = tw.div`block w-full max-w-xs sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;

const Form = tw.form`mx-auto max-w-xs pt-8`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const Error = tw.p`font-medium bg-red-300 text-center p-4 mt-8`;

export default ({
  a14n = null
}) => {
  let { secret } = useParams();
  const [error, setError] = useState(false);
  const [redirect, setRedirect] = useState(false);

  // Кол-во посещений
  const [ticketTypeIndex, setTicketType] = useState(1);
  const [currentPrice, setPrice] = useState(ticketTypes[ticketTypeIndex].price);
  const [selectedPaymentType, setPaymentTypeIndex] = useState(1);

  function SubmitHandler(event) {
    event.preventDefault();
    
    let data = {
      count: ticketTypes[ticketTypeIndex].count,
      price: event.target.price.value,
      payment_type: selectedPaymentType
    }

    CallApiPutAuthorized('/api/tickets/' + secret, data, a14n.token).then((result) => {
      if (result.success) {
        setRedirect('/ticket/' + secret);
        setError(false);
      }
      else {
        setError(result.message);
      }
    })
  }

  function TicketSwitchHandler(e, index) {
    e.preventDefault()
    setTicketType(index)
    setPrice(ticketTypes[index].price)
  }

  return (
    <>
      <AdminHeader a14n={a14n} />
      <Container>
        <Content>
          <MainContainer>
            <MainContent>
              { redirect && <Navigate to={redirect} />}
              <Heading>
                Начислить посещения
              </Heading>

              <Form onSubmit={SubmitHandler}>
                <TicketCountSwitcher>
                  {ticketTypes.map((ticketType, index) => (
                    <TicketSwitchButton active={ticketTypeIndex === index}
                                        key={index}
                                        onClick={(e) => TicketSwitchHandler(e, index)}>
                      {ticketType.switcherText}
                    </TicketSwitchButton>
                  ))}
                </TicketCountSwitcher>
                <Input name="price" type="number" onChange={(e) => setPrice(e.target.value)} placeholder="стоимость" required="1" value={currentPrice} />
                <PaymentTypeSwitcher>
                  {paymentTypes.map((paymentType, index) => (
                    <SwitchButton active={selectedPaymentType === index}
                                  key={index}
                                  onClick={(e) => {e.preventDefault(); setPaymentTypeIndex(index)}}>
                      {paymentType.switcherText}
                    </SwitchButton>
                  ))}
                </PaymentTypeSwitcher>

                <SubmitButton><span className="text">Отправить</span></SubmitButton>
                {error && <Error>{error}</Error>}
              </Form>    

            </MainContent>
          </MainContainer>
        </Content>
      </Container>
  </>
    )
}