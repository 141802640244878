import React, { useState, useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";
import { Container as ContainerBase } from "components/misc/Layouts";
import { CallApiFetchAuthorized, CallApiPutAuthorized } from "helpers/CallApiFetch";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import AdminHeader from 'pages/Admin/Header.js';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-1 sm:my-8 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`p-6 sm:p-12`;
const MainContent = tw.div`mt-4 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold items-center flex`;

const Form = tw.form`mx-auto max-w-xs pt-8`;
const Input = tw.input`w-full px-6 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const Error = tw.p`font-medium bg-red-300 text-center p-4 mt-8`;

function ServiceEdit({
  a14n = null,
  op = 'create',
  heading = 'Создать услугу',
  buttonLabel = 'Создать',
  id = null
}) {
  const params = useParams();

  const [error, setError] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const [service, setService] = useState({
    name: '',
    cost: '',
    toll: '',
    payment: ''
  })

  function SubmitHandler(event) {
    event.preventDefault();
    if (op === 'create') {
      CallApiFetchAuthorized('/api/services', service, a14n.token).then((result) => {
        if (result.success) {
          setRedirect('/admin/services/index');
          setError(false);
        }
        else {
          setError(result.message);
        }
      })
    }
    else {
      CallApiPutAuthorized('/api/services/' + service.id, service, a14n.token).then((result) => {
        if (result.success) {
          setRedirect('/admin/services/index');
          setError(false);
        }
        else {
          setError(result.message);
        }
      })
    }
  }

  useEffect(() => {
    if (op === 'edit') {
      CallApiFetchAuthorized('/api/services/' + params.id, null, a14n.token).then((result) => {
        if (result.success) {
          setService(prevState => {
            return result.data;
          })
        }
        else {
          setError(result.message);
        }
      });
    }
    // eslint-disable-next-line
  }, [a14n.token])

  return (
    <>
      <AdminHeader a14n={a14n} />
      <Container>
        <Content>
          <MainContainer>
            <MainContent>
              { redirect && <Navigate to={redirect} />}
              <Heading>
                {heading}
              </Heading>

              <Form onSubmit={SubmitHandler}>
                <Input name="name" placeholder="Название услуги" required="1" onChange={(event) => setService((prevState) => {return {...prevState, name: event.target.value}})} value={service.name} />
                <Input name="cost" placeholder="Цена" required="1" onChange={(event) => setService((prevState) => {return {...prevState, cost: event.target.value}})} value={service.cost} />
                <Input name="toll" placeholder="Сколько уходит скалодрому" required="1" onChange={(event) => setService((prevState) => {return {...prevState, toll: event.target.value}})} value={service.toll} />
                <Input name="payment" placeholder="Оплата тренеру/менеджеру" required="1" onChange={(event) => setService((prevState) => {return {...prevState, payment: event.target.value}})} value={service.payment} />


                <SubmitButton><span className="text">{buttonLabel}</span></SubmitButton>
                {error && <Error>{error}</Error>}
              </Form>

            </MainContent>
          </MainContainer>
        </Content>
      </Container>
  </>
    )
}

export function CreateService(params) {
  return ServiceEdit(params)
}

export function EditService(params) {
  return ServiceEdit({...params, op: 'edit', heading: 'Изменить услугу', buttonLabel: 'Сохранить'})
}