export async function CallApiFetch($api_call, post_data) {
  const params = {
    method: post_data? 'POST': 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'node_env': process.env.NODE_ENV
    },
    redirect: 'follow', // manual, *follow, error
  };
  if (params.method === 'POST') {
    params.body = JSON.stringify(post_data);
  }
  return fetch(process.env.REACT_APP_BACKEND_URL_PREFIX + $api_call, params).then(res => res.json());
}

export async function CallApiFetchAuthorized($api_call, post_data, token) {
  const args = {
    method: post_data? 'POST': 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'node_env': process.env.NODE_ENV,
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    redirect: 'follow', // manual, *follow, error
  };
  if (post_data) args.body = JSON.stringify(post_data);

  return fetch(process.env.REACT_APP_BACKEND_URL_PREFIX + $api_call, args).then(res => res.json());
}

export async function CallApiPutAuthorized($api_call, data, token) {
  return fetch(process.env.REACT_APP_BACKEND_URL_PREFIX + $api_call, {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'node_env': process.env.NODE_ENV,
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    redirect: 'follow', // manual, *follow, error
    body: JSON.stringify(data)
  }).then(res => res.json());
}

export default CallApiFetch;
