import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CallApiFetch, CallApiFetchAuthorized } from "helpers/CallApiFetch.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import Ticket from 'components/cards/Ticket.js';
import Realistic from "react-canvas-confetti/dist/presets/realistic";

const Content = tw.div`mt-4 flex flex-col items-center bg-white`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold items-center flex`;
const TicketData = styled.div`
  ${tw`pb-4`}
  strong {
    ${tw`ml-2`}
  }
`
const TicketContainer = tw.div`flex flex-col sm:flex-row items-center my-10`
const TicketInfo = tw.div` sm:pl-16 mt-10 sm:mt-0`

export default ({
  a14n = null
}) => {


  let { id } = useParams();
  const current_timestamp = (new Date()).getTime() / 1000;

  const [ticket, setTicket] = useState(null);

  useEffect(() => {
    if (a14n && a14n.token) {
      CallApiFetchAuthorized('/api/tickets/' + id, null, a14n.token).then((result) => {
        setTicket(result.data);
      })
    }
    else {
      CallApiFetch('/api/tickets_public/' + id).then((result) => {
        setTicket(result.data);
      })
    }
  }, [a14n, id]);

  return (
    <>
    <Header a14n={a14n} />
    <Content>
      <Realistic height={window.innerHeight} autorun={{ speed: 1, duration: 5000 }} />
      <Heading>Ваш подарочный абонемент</Heading>
      
      {
        (ticket
        ?
          <>
            <TicketContainer>
              <Ticket ticket={ticket} />
              <TicketInfo>
                <TicketData>Имя: <strong>{ticket.name? ticket.name: "<не активирован>"}</strong></TicketData>
                {
                  ((a14n.is_admin || a14n.is_trainer) || ticket.expire_at_timestamp > current_timestamp) &&
                  <TicketData>
                    Осталось посещений:
                    <strong className="count">{parseInt(ticket.count)}</strong>
                  </TicketData>
                }
                <TicketData>
                  Истекает:
                  {
                    ticket.activate_on_visit ? 
                    <strong className="count">5 недель с первого посещения</strong>
                    :
                    (
                      ticket.expire_at_timestamp < current_timestamp ?
                      <strong className="count">истёк {(new Date(ticket.expire_at_timestamp*1000)).toLocaleDateString()}</strong>
                      :
                      <strong className="count">{ticket.expire_at}</strong>
                    )
                  }
                </TicketData>
              </TicketInfo>
            </TicketContainer>

            <Heading>Поздравляем!</Heading>
          </>
        :
          <p>Загружаем данные...</p>
        )
      }
    </Content>
    </>
  )
}