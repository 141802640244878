import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableRow,
} from "components/ui/table.js"

import { Link } from "react-router-dom";
import { CallApiFetchAuthorized } from "helpers/CallApiFetch.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import AdminHeader from 'pages/Admin/Header.js';

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-1 sm:my-8 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`p-6 sm:p-12`;
const MainContent = tw.div`mt-4 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold items-center flex`;
const BottomLink = tw(Link)`mt-20`;

/*const BackLink = styled.button`
  ${tw`mt-10 tracking-wide font-semibold cursor-pointer bg-primary-500 text-gray-100 py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out  justify-center focus:shadow-outline focus:outline-none`}
  min-width: 200px;
`*/

function Report({
  a14n = null,
  heading = null
}) {
  const params = useParams();
  const [data, setData] = useState({log: null});
  heading = heading || 'Отчет за ' + params.date;

  useEffect(() => {
    if (params.id && params.date) {
      CallApiFetchAuthorized(`/api/services/trainer/${params.id}/${params.date}`, null, a14n.token).then((result) => {
        setData((prevState) => {
          return {...prevState, ...result.data, 'log': result.data.log};
        });
      })
    }
    else {
      CallApiFetchAuthorized(`/api/myreport`, null, a14n.token).then((result) => {
        setData((prevState) => {
          return {...prevState, ...result.data, 'log': result.data.log};
        });
      })
    }
  }, [params.id, params.date, a14n.token]);


  return (
  <>
    <AdminHeader a14n={a14n} />
    <Container>
      <Content>
        <MainContainer>
          <MainContent>
            <Heading>
              {heading}
              {data.trainer && ` ${data.trainer}`}
            </Heading>


            {data.log === null ? 'Загрузка...': (data.log.length === 0 ? '-': '') }
            <Table>
              <TableCaption></TableCaption>
              <TableBody>
                {(data.log || []).map((logItem) => (
                  <TableRow key={logItem.id}>
                    <TableCell>
                      {logItem.id && <Link to={`/admin/services/${logItem.id}?return=admin/services/my`}>{logItem.name}</Link>}
                      {!logItem.id && logItem.name}</TableCell>
                    <TableCell>{logItem.toll}</TableCell>
                    <TableCell>{logItem.payment}</TableCell>
                    <TableCell>{logItem.count}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>Баланс</TableCell>
                  <TableCell><strong>{data.balance}</strong></TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Итого заработано</TableCell>
                  <TableCell></TableCell>
                  <TableCell><strong>{data.total}</strong></TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <BottomLink to="/admin/services/index">Все услуги &gt;&gt;</BottomLink>
          </MainContent>
        </MainContainer>
      </Content>
    </Container>
  </>
  )
}

export function MyReport(params) {
  return Report({...params, heading: 'Мой отчёт за сегодня'})
}

export function ByTrainerAndDate(params) {
  return Report(params)
}