import React from "react";
import { Navigate } from "react-router-dom";

export default ({
  a14n = null,
  setA14n = function() {}
}) => {
  setA14n(prevState => {
    return {name: '', token: '', is_admin: false, is_trainer: false}
  });

  return (
    <>
      <Navigate to='/' />
    </>
  );
}
