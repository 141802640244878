import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { CallApiFetch, CallApiFetchAuthorized } from "helpers/CallApiFetch.js";
import tw from "twin.macro";
import styled from "styled-components";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Ticket from 'components/cards/Ticket.js';
import Select from 'react-select';
import {useNumberSelectConfirm} from 'helpers/NumberSelectConfirm';

const Content = tw.div`mt-4 flex flex-col items-center bg-white`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold items-center flex`;
const TicketData = styled.div`
  ${tw`pb-4`}
  strong {
    ${tw`ml-2`}
  }
`
const Center = tw.div`text-center`;

const TicketContainer = tw.div`flex flex-col sm:flex-row items-center my-10`
const TicketInfo = tw.div` sm:pl-16 mt-10 sm:mt-0`
const TicketControl = tw.div`bg-gray-200 p-8 sm:p-16 mt-10 text-center`
const AdminButton = styled.button`
  ${tw`mx-3 my-3 tracking-wide font-semibold cursor-pointer bg-primary-500 text-gray-100 py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out  justify-center focus:shadow-outline focus:outline-none`}
  min-width: 150px;
  
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`mx-3`}
  }
  &:disabled {
    ${tw`bg-gray-400 cursor-default`}
  }

  &.success {
    ${tw`bg-green-500`}
  }
`;

const LogLink = styled.button`
  ${tw`tracking-wide font-semibold cursor-pointer bg-primary-500 text-gray-100 py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out  justify-center focus:shadow-outline focus:outline-none`}
  min-width: 150px;
`

const StyledSelect = styled(Select)`
  ${tw`mt-5`}
`;

export default ({
  a14n = null
}) => {
  let { id } = useParams();
  const current_timestamp = (new Date()).getTime() / 1000;
  const ONE_DAY = 86400;
  const ONE_WEEK = ONE_DAY*7;
  const FIVE_WEEKS = ONE_WEEK*5;

  const [ticket, setTicket] = useState(null);
  const [setCountHandler, NumberSelectConfirmWrapper, setCountData] = useNumberSelectConfirm({
    defaultCount: 1,
    min: 1,
    max: 9
  });

  useEffect(() => {
    if (a14n && a14n.token) {
      CallApiFetchAuthorized('/api/tickets/' + id, null, a14n.token).then((result) => {
        setTicket(result.data);
        setCountData((prevData) => {
          return {...prevData, max: parseInt(result.data.count)}
        });
      })
    }
    else {
      CallApiFetch('/api/tickets_public/' + id).then((result) => {
        setTicket(result.data);
        setCountData((prevData) => {
          return {...prevData, max: parseInt(result.data.count)}
        });
      })
    }
  }, [a14n, id, setCountData]);

  const [loading, setLoading] = useState(false);
  const [visitStatus, setVisitStatus] = useState({});

  const CountVisitHandler = (event, count = 1) => {
    event.preventDefault();
    setLoading(true);

    CallApiFetchAuthorized('/api/tickets/visit', {count: count, secret: id}, a14n.token).then((result) => {
      setLoading(false);
      if (result.success) {
        setTicket(result.data);
        setVisitStatus({status: true, message: result.message});
      }
      else {
        setVisitStatus({status: false, message: result.message});
      }
    })
  }

  const adminButtons = [];
  const defaultGroups = [{ value: 0, label: 'Самостоятельные' }];
  const [groups, setGroups] = useState(defaultGroups);
  const [currentGroup, setCurrentGroup] = useState({ value: 0, label: 'Самостоятельные'});

  function setGroup(group) {
    CallApiFetchAuthorized('/api/tickets/setgroup', {id: ticket.ticket_id, group_id: group.id}, a14n.token).then((result) => {
      console.log('result=', result);
      if (result.success) {
        setTicket(result.data);
      }
      else {

      }
    })
  }

  function addOneWeekHandler() {
    CallApiFetchAuthorized('/api/tickets/addweek', {id: ticket.ticket_id}, a14n.token).then((result) => {
      if (result.success) {
        setTicket(result.data);
      }
      else {

      }
    })
  }

  if (ticket && (a14n.is_admin || a14n.is_trainer)) {
    if (!ticket.name) {
      adminButtons.push(<Link key="activate" to={`/admin/tickets/activate/${ticket.secret}`}><AdminButton><span className="text">Активировать</span></AdminButton></Link>);
    }
    if (ticket.name && ticket.count > 0 && ticket.expire_at_timestamp > current_timestamp) {
      adminButtons.push(
        <>
        <AdminButton
          key="visit_one"
          disabled={loading || visitStatus.status}
          className={visitStatus.status? 'success': ''}
          onClick={(e) => setCountHandler().then((count) => CountVisitHandler(e, count))}>
          {
            loading ? 
              <span className="text">Подождите...</span>
            :
            (
              visitStatus.message? 
                <span className="text">{visitStatus.message}</span>
              :
                (
                  <span className="text">Посещение</span>
                )
            )
          }
        </AdminButton>
        {NumberSelectConfirmWrapper}
        </>);
    }
    if (ticket.name && (ticket.count <= 0 || ticket.expire_at_timestamp < current_timestamp)) {
      adminButtons.push(
        <Link key="activate" to={`/admin/tickets/payment/${ticket.secret}`}>
          <AdminButton>
            <span className="text">Оплата</span>
          </AdminButton>
        </Link>);
    }
    if (ticket.name && ticket.count > 0 && // На абонементе остались посещения
                        (
                          (
                            ticket.expire_at_timestamp < current_timestamp && // Абонемент истек
                            ticket.expire_at_timestamp > (current_timestamp - ONE_WEEK) // но не позднее чем 1 неделю назад
                           ) ||
                          (
                            ticket.expire_at_timestamp > current_timestamp && // Абонемент ещё не истек
                            ticket.expire_at_timestamp < (current_timestamp + ONE_WEEK + ONE_DAY) // но истекает менее чем через 1 неделю
                          )
                        )) {
      console.log(ticket.expire_at_timestamp - ticket.last_payed_timestamp, FIVE_WEEKS + ONE_DAY);
      if (ticket.expire_at_timestamp - ticket.last_payed_timestamp <= FIVE_WEEKS + ONE_DAY) {
        // и абонемент ещё не продлялся
        adminButtons.push(
          <AdminButton onClick={addOneWeekHandler}>
            <span className="text">Продлить на 1 неделю</span>
          </AdminButton>
        );
      }
      else {
        adminButtons.push(
          <AdminButton disabled>
            <span className="text">Абонемент уже был продлен</span>
          </AdminButton>
        );
      }
    }

    adminButtons.push(
      <StyledSelect key="group" styles={{
        control: (baseStyles, state) => ({
            ...baseStyles,
            padding: 10,
            borderColor: 'rgb(237 242 247)'
          }),
        }}
        menuPlacement="top"
        name="trainer_id"
        options={groups}
        isLoading={loading}
        value={currentGroup}
        onChange={setGroup}
        placeholder="Выберите группу..." />
      );

  }

  useEffect(() => {
    if (a14n.token && ticket) {
      CallApiFetchAuthorized('/api/groups', null, a14n.token).then((result) => {
        //setTicket(result.data);
        const groups = [...defaultGroups];
        if (result.success) {
          result.data.forEach((item) => {
            groups.push({ id: item.id, label: item.name });
            if (ticket && ticket.group_id === item.id) {
              setCurrentGroup(groups[groups.length - 1]);
            }
          })

          if (!ticket.group_id) {
            setCurrentGroup(defaultGroups[0]);
          }
        }
        else {

        }
        setGroups(groups);
      })
    }
    // eslint-disable-next-line
  }, [a14n.token, ticket]);

  Date.toDateString = function() {

  }

  return (
    <AnimationRevealPage>
      <Header a14n={a14n} />
      <Content>
        <Heading>Ваш абонемент</Heading>
        
        {
          (ticket
          ?
            <>
              <TicketContainer>
                <Ticket ticket={ticket} />
                <TicketInfo>
                  <TicketData>Имя: <strong>{ticket.name? ticket.name: "<не активирован>"}</strong></TicketData>
                  <TicketData>Телефон: <strong>{ticket.phone? ticket.phone: "<не указан>"}</strong></TicketData>
                  {
                    ((a14n.is_admin || a14n.is_trainer) || ticket.expire_at_timestamp > current_timestamp) &&
                    <TicketData>
                      Осталось посещений:
                      <strong className="count">{parseInt(ticket.count)}</strong>
                    </TicketData>
                  }
                  <TicketData>
                    Последняя оплата:
                    <strong className="count">{ticket.last_payed}</strong>
                  </TicketData>
                  <TicketData>
                    Истекает:
                    {
                      ticket.activate_on_visit ? 
                      <strong className="count">5 недель с первого посещения</strong>
                      :
                      (
                        ticket.expire_at_timestamp < current_timestamp ?
                        <strong className="count">истёк {(new Date(ticket.expire_at_timestamp*1000)).toLocaleDateString()}</strong>
                        :
                        <strong className="count">{ticket.expire_at}</strong>
                      )
                    }
                  </TicketData>
                  <TicketData>
                    <Center>
                      <Link to={`/ticket/log/${ticket.secret}`}><LogLink>Журнал</LogLink></Link>
                    </Center>
                  </TicketData>
                </TicketInfo>
              </TicketContainer>

              {adminButtons.length > 0 && 
                <TicketControl>
                  {adminButtons}
                </TicketControl>
              }
            </>
          :
            <p>Загружаем данные...</p>
          )
        }
      </Content>
    </AnimationRevealPage>
  )
}