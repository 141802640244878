import React, { useState, useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";
import { Container as ContainerBase } from "components/misc/Layouts";
import { CallApiFetchAuthorized, CallApiPutAuthorized } from "helpers/CallApiFetch";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import AdminHeader from 'pages/Admin/Header.js';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import Select from 'react-select';

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-1 sm:my-8 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`p-6 sm:p-12`;
const MainContent = tw.div`mt-4 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold items-center flex`;

const Form = tw.form`mx-auto max-w-xs pt-8`;
const Input = tw.input`w-full px-6 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const Error = tw.p`font-medium bg-red-300 text-center p-4 mt-8`;
const StyledTimePicker = styled(TimePicker)`
  ${tw`ml-5 mt-5`}
  .react-time-picker__wrapper {
    ${tw`px-2 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white`}
  }
`;

const StyledSelect = styled(Select)`
  ${tw`mt-5`}
`;

const WeekdayButton = styled.button`
  ${tw`mt-5 mr-1 font-semibold bg-gray-400 text-gray-100 w-10 py-4 rounded-lg hover:bg-gray-500 transition-all duration-300 ease-in-out justify-center focus:shadow-outline focus:outline-none`}

  &.active {
    ${tw`bg-green-500`}
  }
`;

function GroupEdit({
  a14n = null,
  op = 'create',
  heading = 'Создать группу',
  buttonLabel = 'Создать',
  id = null
}) {
  const params = useParams();

  const [error, setError] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const [trainers, setTrainers] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [currentTrainer, setCurrentTrainer] = useState(null);

  const [group, setGroup] = useState({
    name: '',
    start: '10:00',
    end: '11:30',
    mon: false, tue: false, wed: false, thu: false, fri: false, sat: false, sun: false
  })

  const weekdaysNames = {
    mon: 'ПН',
    tue: 'ВТ',
    wed: 'СР',
    thu: 'ЧТ',
    fri: 'ПТ',
    sat: 'СБ',
    sun: 'ВС'
  }

  function SubmitHandler(event) {
    event.preventDefault();
    group.trainer_id = currentTrainer.value;
    if (op === 'create') {
      CallApiFetchAuthorized('/api/groups', group, a14n.token).then((result) => {
        if (result.success) {
          setRedirect('/admin/groups/index');
          setError(false);
        }
        else {
          setError(result.message);
        }
      })
    }
    else {
      CallApiPutAuthorized('/api/groups/' + group.id, group, a14n.token).then((result) => {
        if (result.success) {
          setRedirect('/admin/groups/index');
          setError(false);
        }
        else {
          setError(result.message);
        }
      })
    }
  }

  function loadTrainers() {
    return new Promise((resolve, reject) => {
      CallApiFetchAuthorized('/api/trainers', null, a14n.token).then((result) => {
        if (result.success) {
          setTrainers(prevState => {
            const newState = [];
            result.data.forEach(item => {
              newState.push({ value: item.id, label: item.name });
            });
            return newState;
          }, resolve);
        }
        else {
          setError(result.message);
          reject();
        }
      });
    });
  }

  useEffect(() => {
    if (op === 'edit') {
      CallApiFetchAuthorized('/api/groups/' + params.id, null, a14n.token).then((result) => {
        if (result.success) {
          setGroup(prevState => {
            return result.data;
          })

          setLoading(false);
          loadTrainers();
        }
        else {
          setError(result.message);
        }
      });
    }
    else {
      setLoading(false);
      loadTrainers();
    }
    // eslint-disable-next-line
  }, [a14n.token])

  useEffect(() => {
    trainers.forEach((trainer) => {
      if (trainer.value === group.trainer_id) {
        setCurrentTrainer(trainer);
      }
    })
  }, [group.trainer_id, trainers]);

  function toggleWeekday(event, day) {
    event.preventDefault();
    setGroup((prevState) => {
      let newState = {...prevState};
      newState[day] = !newState[day];
      return newState;
    })
  }

  return (
    <>
      <AdminHeader a14n={a14n} />
      <Container>
        <Content>
          <MainContainer>
            <MainContent>
              { redirect && <Navigate to={redirect} />}
              <Heading>
                {heading}
              </Heading>

              <Form onSubmit={SubmitHandler}>
                <Input name="name" placeholder="Название группы" required="1" onChange={(event) => setGroup((prevState) => {return {...prevState, name: event.target.value}})} value={group.name} />
                <StyledSelect styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: 10,
                    borderColor: 'rgb(237 242 247)'
                  }),
                }} 
                name="trainer_id" 
                options={trainers} 
                isLoading={isLoading}
                onChange={setCurrentTrainer}
                value={currentTrainer} placeholder="Выберите тренера..." />

                <div>
                  Начало: <StyledTimePicker name="start" value={group.start} onChange={(value) => setGroup((prevState) => {return {...prevState, start: value}})} required="1" />
                </div>
                <div>
                  Конец: <StyledTimePicker name="end" value={group.end} onChange={(value) => { console.log('value=', value); setGroup((prevState) => {return {...prevState, end: value} } ) }} required="1" />
                </div>


                {Object.keys(weekdaysNames).map((day) => 
                  <WeekdayButton key={day} onClick={(e) => toggleWeekday(e, day)} className={group[day]? 'active': ''}>{weekdaysNames[day]}</WeekdayButton>
                )}

                <SubmitButton><span className="text">{buttonLabel}</span></SubmitButton>
                {error && <Error>{error}</Error>}
              </Form>    

            </MainContent>
          </MainContainer>
        </Content>
      </Container>
  </>
    )
}

export function CreateGroup(params) {
  return GroupEdit(params)
}

export function EditGroup(params) {
  return GroupEdit({...params, op: 'edit', heading: 'Изменить группу', buttonLabel: 'Сохранить'})
}