import React, { useState, useEffect } from "react";
import { useParams, useSearchParams, Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Container as ContainerBase } from "components/misc/Layouts";
import { CallApiFetchAuthorized } from "helpers/CallApiFetch";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import AdminHeader from 'pages/Admin/Header.js';

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-1 sm:my-8 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`p-6 sm:p-12`;
const MainContent = tw.div`mt-4 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold items-center flex`;

//const Error = tw.p`font-medium bg-red-100 text-center p-4 mt-8`;

const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const CancelButton = styled(Link)`
  ${tw`mt-5 tracking-wide font-semibold bg-secondary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-secondary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const Error = tw.p`font-medium bg-red-300 text-center p-4 mt-8`;

export default ({
  a14n = null,
  headingText = "Услуга"
}) => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const [service, setGroup] = useState({service: {}, members: []});
  const [error, setError] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const return_to = searchParams.get('return') || 'admin/services/index';

  useEffect(() => {
    CallApiFetchAuthorized('/api/services/' + params.id, null, a14n.token).then((result) => {
      setGroup((prevState) => {
        console.log('prevState=', prevState);
        return {...prevState, ...result.data};
      });
    })
  }, [a14n.token, params.id]);

  function ServiceLogHandler(event) {
    event.preventDefault();
    CallApiFetchAuthorized('/api/services/log/' + service.id, service, a14n.token).then((result) => {
      if (result.success) {
        setRedirect('/admin/services/my');
        setError(false);
      }
      else {
        setError(result.message);
      }
    })
  }

  return (
  <>
    <AdminHeader a14n={a14n} />
    <Container>
      <Content>
        <MainContainer>
          <MainContent>
            { redirect && <Navigate to={redirect} />}

            <Heading>
              { service.name && `Услуга ${service.name}`}
            </Heading>

            <SubmitButton onClick={ServiceLogHandler}>Записать</SubmitButton>
            {error && <Error>{error}</Error>}

            <CancelButton to={`/${return_to}`}>Отмена</CancelButton>
          </MainContent>
        </MainContainer>
      </Content>
    </Container>
  </>
  );
}

