import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Footer from "components/footers/MiniCenteredFooter.js";
const HighlightedText = tw.span`text-primary-500`

export default ({a14n = null}) => {
  return (
    <AnimationRevealPage>
      <Header a14n={a14n} />
      <Pricing 
        heading={<>Наши <HighlightedText>цены</HighlightedText></>}
      />
      <Footer/>
    </AnimationRevealPage>
  );
};
