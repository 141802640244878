import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
//import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
//import ContactDetails from "components/cards/ThreeColContactDetails.js";
//import { Map } from "components/features/map.js";

import Schedule from "components/front/Schedule.js";

const HighlightedText = tw.span`text-primary-500`

export default ({a14n = null}) => {
  return (
    <AnimationRevealPage>
      <Header a14n={a14n} />
      
      <Schedule 
        heading={<>Расписание <HighlightedText>тренировок</HighlightedText></>}
        description=""
      />

      <Footer />
    </AnimationRevealPage>
  );
};
