import React, { useState, useEffect } from "react";
import {
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "components/ui/table.js"
import { useParams } from "react-router-dom";
import { Container as ContainerBase } from "components/misc/Layouts";
import { CallApiFetchAuthorized } from "helpers/CallApiFetch";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AdminHeader from 'pages/Admin/Header.js';

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex overflow-y-auto`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-1 sm:my-8 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`p-6 sm:p-12`;
const MainContent = tw.div`mt-4 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold items-center flex`;

//const Error = tw.p`font-medium bg-red-100 text-center p-4 mt-8`;

export default ({
  a14n = null,
  headingText = "Группы"
}) => {
  const params = useParams();

  const [data, setData] = useState({group: {}, tickets: [], log: []});

  useEffect(() => {
    CallApiFetchAuthorized('/api/groups/graph/' + params.id, null, a14n.token).then((result) => {
      setData((prevState) => {
        console.log('prevState=', prevState);
        return {...prevState, ...result.data};
      });
    })
  }, [a14n.token, params.id]);

  const verticalTextStyles = {
    'writing-mode': 'vertical-rl',
    '-moz-transform': 'scale(-1, -1)',
    '-webkit-transform': 'scale(-1, -1)',
    '-o-transform': 'scale(-1, -1)',
    '-ms-transform': 'scale(-1, -1)',
    'transform': 'scale(-1, -1)',
    'text-orientation': 'mixed',
    'white-space': 'nowrap',
    'max-height': '100px',
    'text-overflow': 'ellipsis',
    'overflow': 'hidden'
  }
  return (
  <>
    <AdminHeader a14n={a14n} />
    <Container>
      <Content>
        <MainContainer>
          <MainContent>
            <Heading>
              { data.group.name && `${data.group.name} :: посещаемость`}
            </Heading>
 
             <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>&nbsp;</TableHead>
                  {data.tickets.map((member) => (
                    <TableHead><span style={verticalTextStyles}>{member.name}</span></TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {data.log.map((item) => (
                  <TableRow key={item.date}>
                    <TableCell>{item.date}</TableCell>
                    {data.tickets.map((ticket) =>(
                      item.items[ticket.ticket_id]?
                        <TableCell style={{'text-align': 'center', 'background': 'lightgreen'}}>{item.items[ticket.ticket_id]}</TableCell>
                        :
                        <TableCell></TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </MainContent>
        </MainContainer>
      </Content>
    </Container>
  </>
  );
}

